<template>
  <modal
    height="auto"
    :name="modalName"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <svg-icon
        name="download"
        class="modal-body__icon"
      />

      <heading level="3">
        Выгрузка началась
      </heading>

      <div class="modal-body__text">
        Это может занять некоторое время.
        Посмотреть статус выгрузки и скачать файл вы можете в разделе «Выгрузки из системы».
      </div>

      <x-btn
        primary
        v-bind="linkBtnAttr"
        :target="openInNewTab ? '_blank' : '_self'"
        class="modal-body__btn"
        @click="$modal.hide(modalName)"
      >
        Перейти в раздел
      </x-btn>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    CloseButton,
    Heading
  },

  data () {
    return {
      modalName: 'export-modal',
      openInNewTab: false
    }
  },

  computed: {
    linkBtnAttr () {
      if (this.openInNewTab) {
        return {
          href: `${process.env.VUE_APP_SELLER_CABINET_URL}/exports-list`
        }
      }

      return {
        to: { name: 'exports' }
      }
    }
  },

  methods: {
    onBeforeOpen (e) {
      this.openInNewTab = e.params?.openInNewTab
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px
    text-align center

    &__icon
      width 64px
      height 64px
      color #5b40ff

    &__text
      font-size 14px
      color #3c3c47

    &__btn
      width 232px
      margin-top 20px
</style>
