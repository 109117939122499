<template>
  <div class="file-upload">
    <input
      id="upload"
      ref="inputFile"
      type="file"
      class="file-upload__default"
      :disabled="disabled"
      :accept="accept"
      @change="onUpload($event.target.files)"
    >

    <label
      for="upload"
      class="file-upload__wrapper"
    >
      <slot>
        <x-input
          :placeholder="placeHolder"
          class="file-upload__mask"
          :class="{'file-upload__mask_choosed': fileChosen}"
        />
        <reset-button
          v-if="hasReset && fileChosen"
          squared-left
          class="file-upload__clear"
          @click.native.prevent="clearFile"
        />
        <x-btn
          v-if="!(hasReset && fileChosen)"
          :disabled="disabled"
          squared-left
          class="file-upload__button"
        >
          Выбрать файл
        </x-btn>
      </slot>
    </label>
  </div>
</template>

<script>
import ResetButton from '@/components/Interface/ResetButton.vue'

export default {
  components: {
    ResetButton
  },

  props: {
    accept: {
      type: String,
      default: '.XML, .XLSX'
    },
    uploaded: Boolean,
    disabled: Boolean,
    hasReset: Boolean
  },

  data () {
    return {
      fileChosen: false,
      fileName: ''
    }
  },

  watch: {
    uploaded (uploaded) {
      if (!uploaded) return
      this.clearFile()
    }
  },

  computed: {
    placeHolder () {
      return this.fileChosen ? this.fileName : 'Файл не выбран'
    }
  },

  methods: {
    onUpload (files) {
      if (!files.length) return

      this.fileName = files[0].name
      this.fileChosen = true
      this.$emit('upload', files[0])
    },

    clearFile () {
      this.fileName = ''
      this.fileChosen = false
      this.$refs.inputFile.value = ''
      this.$emit('reset')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .file-upload
    position relative
    cursor pointer

    &__default
      position absolute
      z-index 1
      width 100%
      top 0
      left 0
      right 0
      bottom 0
      font-size 0
      cursor pointer
      opacity 0

    &__wrapper
      display flex
      cursor pointer

    &__mask
      width 60%
      text-overflow ellipsis
      pointer-events none

      >>>.x-input__border
        border-radius 4px 0 0 4px

      &_choosed
        width 100%

        >>> input
          &::placeholder
            color $colors.gray-darkest2

    &__button,
    &__clear
      width 40%
      pointer-events none
</style>
