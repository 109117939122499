export default {
  SELLER_DASHBOARD: 'seller_cabinet.dashboard',
  SELLER_DASHBOARD_EXTENDED: 'seller_cabinet.dashboard_extended',
  SELLER_REPORT_TURNOVER: 'seller_cabinet.report_turnover',
  SELLER_ORDER_LIST: 'seller_cabinet.order_list',
  SELLER_PRODUCT_LIST: 'seller_cabinet.product_list',
  SELLER_PRICES_STOCKS: 'seller_cabinet.prices_and_stocks',
  SELLER_PROMO: 'seller_cabinet.promo',

  ANALYTICS_INDICATORS: 'analytics.indicators',
  ANALYTICS_POSITIONS: 'analytics.positions',
  ANALYTICS_ABC_REPORT: 'analytics.abc_report',
  ANALYTICS_CONTENT: 'analytics.content',
  ANALYTICS_PRICES: 'analytics.prices',
  ANALYTICS_COMPETITORS_COMPARE: 'analytics.competitors_compare',

  ORM_ANALYSE_SELLERS: 'orm.analyse_sellers',
  ORM_ANALYSE_BRANDS: 'orm.analyse_brands',
  ORM_ANALYSE_CATEGORIES: 'orm.analyse_categories',
  ORM_ANALYSE_PRODUCTS: 'orm.analyse_products'
}
