<template>
  <x-placeholder
    class="no-shops"
    title="Нет данных"
    :desc="`Чтобы получить данные ${data}, вам нужно добавить хотя бы один магазин`"
  >
    <template #bottom>
      <x-btn
        @click="goToShops"
      >
        Добавить магазин
      </x-btn>
    </template>
  </x-placeholder>
</template>

<script>
import ROUTES from '@/router/ROUTES'

export default {
  props: {
    data: {
      type: String,
      default: ''
    }
  },

  methods: {
    goToShops () {
      this.$router.push({ name: ROUTES.MARKETPLACES.name, query: { add: 1 } })
    }
  }
}
</script>
