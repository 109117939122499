<template>
  <div class="tabs">
    <ul class="tabs__list">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        class="tabs__item"
        :class="[
          { 'active': tab.path === active },
          { 'disabled': tab.disabled }
        ]"
        @click="clickToItem(tab)"
      >
        <a
          class="tabs__link"
          :class="tab.class"
        >
          <svg-icon
            v-if="tab.icon"
            :name="tab.icon"
            class="tabs__icon"
            :class="tab.iconClassList"
          />

          {{ tab.title }}

          <span
            v-if="tab.counter"
            class="tabs__counter"
          >
            {{ tab.counter }}
          </span>
        </a>
      </li>
    </ul>
    <x-divider class="mt-1" />
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    active: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },

  methods: {
    clickToItem (tab) {
      if (!Object.prototype.hasOwnProperty.call(tab, 'disabled') || !tab.disabled) {
        if (this.type) {
          this.$router.replace({
            query: { ...this.$route.query, [this.type]: tab.path }
          })
        }
        this.$emit('click', tab.path)
      }
    }
  }
}
</script>

<style lang="stylus">
  .tabs
    position relative

    &__list
      display flex
      margin 0
      padding-bottom 2px
      overflow auto
      list-style none
      -webkit-tap-highlight-color transparent
      -ms-overflow-style none

      &::-webkit-scrollbar
        width 8px
        height 4px
        background-color rgba(44, 44, 55, .03);
        border-radius 4px
      &::-webkit-scrollbar-thumb
        background-color #a3a3ac
        border-radius 4px

    &__item
      position relative
      white-space nowrap
      cursor pointer

      &.active
        .tabs__link
          border-bottom-color #5b40ff
          border-top-right-radius 4px
          border-top-left-radius 4px
          background-color rgba(91, 64, 255, 0.10)
          color black

        .tabs__counter
          font-weight $font-weights.normal

      &.disabled
        .tabs__link
          opacity .5
          pointer-events none

      &:last-of-type
        margin-right 0

    &__link
      position relative
      display flex
      align-items center
      height 34px
      line-height 26px
      font-size 18px
      padding 4px 16px
      text-decoration none
      color #3c3d47
      border-bottom 2px solid transparent
      transition border-bottom-color .3s

      &:hover
        border-bottom-color #5b40ff

    &__counter
      margin-left 4px
      color #7b7b85

    &__icon
      width 28px
      height 28px
      margin-right 3px

      &_viewtype_end
        position absolute
        top 50%
        left 100%
        margin-right 0
        margin-left 6px
        transform translateY(-50%)

      &_content_pointer
        width 17px
        height 17px
</style>
